import { PropsWithChildren, createContext, useContext, useState } from "react";
import { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { useTracking } from "../tracking";
import JSZip from "jszip";
import fileSaver from "file-saver";
import { GenerationSessionContext } from "./GenerationSession";

interface DownloadArgs {
  sessionId: string;
  files: SandpackBundlerFiles;
}

export const DownloadProjectContext = createContext<{
  download: (args: DownloadArgs) => Promise<void>;
  isDownloading: boolean;
  isAllowed: boolean;
}>({
  download: () => Promise.resolve(),
  isDownloading: false,
  isAllowed: true,
});

export function DownloadProjectProvider(props: PropsWithChildren) {
  const { trackEvent } = useTracking();
  const [isDownloading, setDownloadingState] = useState(false);
  const { entitlements } = useContext(GenerationSessionContext);

  const downloadEntitlement = entitlements?.find(
    (entitlement) => entitlement.name === "feature-figma-dev-mode-download",
  );
  const isAllowed = downloadEntitlement ? downloadEntitlement.isGranted : true; // Permissive by default

  const download = async (args: DownloadArgs) => {
    setDownloadingState(true);
    try {
      trackEvent("playground.exported_code.initiated", {
        action: "zip",
      });
      const files = transformFiles(args.files, [
        amendPackageJson,
        addTempStaticFolder,
      ]);
      const zipFolder = await createZip(files);
      fileSaver.saveAs(zipFolder, `${args.sessionId}.zip`);
      setDownloadingState(false);
      trackEvent("playground.exported_code.success", {
        action: "zip",
      });
    } catch (error) {
      setDownloadingState(false);
      trackEvent("playground.exported_code.failed", {
        action: "zip",
      });
    }
  };

  return (
    <DownloadProjectContext.Provider
      value={{
        download,
        isDownloading,
        isAllowed,
      }}
    >
      {props.children}
    </DownloadProjectContext.Provider>
  );
}

const createZip = async (files: SandpackBundlerFiles) => {
  const zip = new JSZip();

  Object.keys(files).forEach((file) => {
    zip.file(file, files[file].code);
  });

  const zipFolder = await zip.generateAsync({ type: "blob" });

  return zipFolder;
};

type TransformFilesCallback = (files: SandpackBundlerFiles) => void;

const transformFiles = (
  files: SandpackBundlerFiles,
  funcs: TransformFilesCallback[],
) => {
  const filesClone = { ...files };
  funcs.forEach((func) => {
    func(filesClone);
  });
  return filesClone;
};

const amendPackageJson: TransformFilesCallback = (files) => {
  const packageJson = files["/package.json"];

  // We patch `main` to get hot reloading working, but it causes problems when running locally
  const parsedPackageJson = JSON.parse(packageJson.code);
  delete parsedPackageJson.main;
  files["/package.json"].code = JSON.stringify(parsedPackageJson, null, 2);
};

const addTempStaticFolder: TransformFilesCallback = (files) => {
  // hack to ensure that a static directory exists for parcel
  files["/static/"] = {
    code: "",
  };
};
