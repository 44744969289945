import { useSandpack } from "@codesandbox/sandpack-react";
import { useEffect } from "react";
import { useTracking } from "../tracking";

export const useTrackCopy = () => {
  const { sandpack } = useSandpack();
  const { trackEvent } = useTracking();

  useEffect(() => {
    const handleOnCopy = () => {
      trackEvent("playground.exported_code.success", {
        action: "copy_to_clipboard",
      });
    };

    let editor: HTMLDivElement | null = null;
    const observer = new MutationObserver(() => {
      editor = document.querySelector<HTMLDivElement>(
        `.cm-content[role="textbox"]`,
      );

      if (editor) {
        observer.disconnect();
        editor.addEventListener("copy", handleOnCopy);
      }
    });
    observer.observe(document, { subtree: true, childList: true });

    return () => {
      observer?.disconnect();
      editor?.removeEventListener("copy", handleOnCopy);
    };
  }, [sandpack.activeFile, trackEvent]);

  return null;
};
